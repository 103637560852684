import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { Icon, ComingSoonOverlay, LoadingSpinner } from '@/components/general';
import ACTION_TYPE from '@/flow/Order/BookingFlow/action-type';
import ROUTE_NAME from '@/helpers/url-constants';
import { listingTypeParser } from '@/helpers/parsers';
import VehicleImage from './VehicleImage';
import style from './vehicle-item.module.scss';

const VehicleItem = props => {
  const { data, isVatIncluded = true, isSwapVehicle, isSwapRoute } = props;
  const dispatch = useDispatch();
  const cx = classNames.bind(style);
  const router = useRouter();
  const { bookingId } = router.query;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [vehicleDetailLoading, setVehicleDetailLoading] = useState(false);

  const vehicleTags = [
    {
      id: 0,
      name: data?.fuelType,
      icon: 'fuel',
    },
    {
      id: 1,
      name: data?.gearBoxType,
      icon: 'gearbox',
    },
    {
      id: 2,
      name: data?.doorType,
      icon: 'car-door',
    },
  ];

  const tags = [
    {
      id: 1,
      name: 'Available',
      status: data?.fleetioLink === 1,
    },
    {
      id: 2,
      name: 'Coming soon',
      status: data?.fleetioLink === 0 && data?.listingType !== listingTypeParser.preOrder,
    },
    {
      id: 3,
      name: 'Pre-order',
      status: data?.listingType === listingTypeParser.preOrder,
    },
    {
      id: 4,
      name: data?.offer,
      status: data?.offerStatus,
    },
  ];

  const onViewDetail = () => {
    localStorage.setItem('selectedVehicle', data?.id);
    dispatch({ type: ACTION_TYPE.RESET_APPLY_PROMOTION });
    setVehicleDetailLoading(true);
    router.push(`${ROUTE_NAME.BROWSE_VEHICLES}/${data?.id}`);
  };
  const swapVehicleDetail = () => {
    localStorage.setItem('selectedVehicle', data?.id);
    setVehicleDetailLoading(true);
    router.push(`${ROUTE_NAME.MY_BOOKINGS}/${bookingId}${ROUTE_NAME.SWAP_BROWSE}/${data?.id}`);
  };
  const getPricing = value => {
    return value; // isVatIncluded ? value : value - value * 0.2;
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <div
      id={data?.id}
      className={cx(style.item)}
      onClick={!isSwapRoute ? onViewDetail : swapVehicleDetail}
    >
      {vehicleDetailLoading && (
        <div className={style.vehicleDetailLoading}>
          <LoadingSpinner />
        </div>
      )}
      <div className={style.wrapper}>
        {isSwapVehicle ? (
          <div className={style.tagsOnTop}>
            <span className={cx(style.currentVehicleBubble)}>
              <p> Current vehicle</p>
              <Icon icon="approve" size={24} color={'#FF05D7'} />
            </span>
          </div>
        ) : (
          <div className={style.tagsOnTop}>
            {tags?.map(item =>
              item?.status && item?.name ? (
                <span
                  id={`type${item.id}`}
                  key={item.id}
                  className={cx(style.bubbles, {
                    [style['selected-bubbles']]: item.id === 1,
                    [style['coming-soon']]: item.id === 2,
                    [style['pre-order']]: item.id === 3,
                  })}
                >
                  {item.name}
                </span>
              ) : (
                ''
              ),
            )}
          </div>
        )}

        <div className={style.thumbnail}>
          {data?.fleetioLink === 0 && data?.listingType !== listingTypeParser.preOrder && (
            <ComingSoonOverlay />
          )}
          <VehicleImage data={data} quality scrollPosition={scrollPosition} />
        </div>
        <div className={style.content}>
          <div className={style.details}>
            <div className={style.title}>{`${data?.carMake} ${data?.carModel}`}</div>
            {data?.carVariant ? <div className={style.carVariant}>{data?.carVariant}</div> : null}
            {data?.optional ? <div className={style.optText}>{data?.optional}</div> : null}
            <div className={style.tags_pricing}>
              <ul className={style.tags}>
                {vehicleTags?.map(item => (
                  <li key={item.id}>
                    <Icon className={style.icon} icon={item.icon} size={16} color={'#00E6F0'} />
                    <span>{item.name}</span>
                  </li>
                ))}
              </ul>
              <div className={style.pricing}>
                <div className={style.value}>£{getPricing(data?.pricing)}</div>
                <div className={style.note}>month {isVatIncluded && `incl VAT`}</div>
              </div>
            </div>
          </div>
          {data?.featuresBenefitsStatus ? (
            <ul className={style.benefits}>
              {data?.featuresBenefits?.map(item => (
                <li key={item.id}>• {item.value}</li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
      <div className={style.footer}>
        {data?.additionalOfferStatus ? data?.additionalOffer : null}
      </div>
    </div>
  );
};

export default VehicleItem;
