/* eslint-disable import/prefer-default-export */
export const typeOfFineParser = {
  DON: 'don',
  FINE: 'fine',
  PCN: 'pcn',
};

export const promoValueTypeParser = {
  percentage: 'percentage',
  flatfee: 'flatfee',
};

export const promoTypeParser = {
  onetime: 'onetime',
  ongoing: 'ongoing',
};

export const listingTypeParser = {
  preOrder: 'pre-order',
  regular: 'regular',
};
