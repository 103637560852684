import ACTION_TYPE from '@/flow/Order/BookingFlow/action-type';
import REQUEST from '@/helpers/http.service';
import API_ENDPOINTS from '@/helpers/apiEndpoint';
import LISTINGS_TYPE from './action-type';

export const getMetaData = () => async dispatch => {
  const res = await REQUEST({
    method: 'GET',
    url: API_ENDPOINTS.META_DATA,
    auth: true,
  });
  if (res) {
    dispatch({
      type: LISTINGS_TYPE.META_DATA,
      payload: res?.data?.data,
    });
  }
  return res;
};

export const getCarMake = query => async () => {
  const res = await REQUEST({
    method: 'GET',
    url: API_ENDPOINTS.LISTING_GET_CAR_MAKE + query,
    auth: true,
  });
  if (res) {
    return res.data;
  }
  return res;
};

export const getCarModel = data => async () => {
  const res = await REQUEST({
    method: 'POST',
    url: API_ENDPOINTS.LISTING_GET_CAR_MODEL,
    data,
    auth: true,
  });
  if (res) {
    return res.data;
  }
  return res;
};

export const browseVehicles = data => async () => {
  const res = await REQUEST({
    method: 'POST',
    url: API_ENDPOINTS.LISTING_BROWSE_VEHICLE,
    data,
  });
  if (res) {
    return res.data;
  }
  return res;
};

export const browseSuggestedVehicles = (query, fkPartnerId, vehicleBookingId) => async () => {
  const queryValue = query ? `${query}` : '';
  const partnerIdValue = fkPartnerId ? `/${fkPartnerId}` : '';
  const vehicleId = vehicleBookingId ? `${vehicleBookingId}` : '';
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.LISTING_SUGGESTED_LISTING}${queryValue}${partnerIdValue}${vehicleId}`,
  });
  if (res) {
    return res.data;
  }
  return res;
};

// export const browseVehicleDetail = id => async () => {
//   const res = await REQUEST({
//     method: 'GET',
//     url: `${API_ENDPOINTS.LISTING_BROWSE_VEHICLE}/${id}`,
//     auth: true,
//   });

//   return res.data;
// };

export const getColors = async () => {
  const res = await REQUEST({
    method: 'GET',
    url: API_ENDPOINTS.LISTING_COLOR,
    auth: true,
  });
  if (res?.data?.status) {
    const colorArr = res?.data?.data?.map(data => ({
      value: data?.id,
      label: data?.name,
      colorCode: data?.colorCode,
    }));
    return { status: res?.data?.status, data: colorArr };
  }
  return res?.data;
};

export const preBookingChecks = async listingId => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.PRE_BOOKING_CHECKS}?listingId=${listingId}`,
    auth: true,
  });
  return res?.data;
};

export const requestUnavailebleCar = data => async () => {
  const res = await REQUEST({
    method: 'POST',
    url: API_ENDPOINTS.WAITING_LIST,
    data,
    auth: false,
  });

  return res?.data;
};

export const getParentBookingDetails = async bookingId => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.BOOKINGS_DETAILS}/${bookingId}`,
    auth: true,
  });
  return res?.data;
};

export const getParentVoucherDetails = async (listingId, query) => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.LISTING_BROWSE_VEHICLE}/${listingId}${query}`,
    auth: true,
  });
  return res?.data;
};

export const fetchAppliedVoucherData =
  ({ showLoading, hideLoading, toastError, vehicleId, bookingId }) =>
  async dispatch => {
    dispatch(showLoading());
    const parentBookingRes = await getParentBookingDetails(bookingId);

    if (!parentBookingRes?.status) {
      dispatch(hideLoading());
      toastError({ message: parentBookingRes?.message });
      return;
    }

    if (parentBookingRes?.status && parentBookingRes?.data) {
      dispatch({
        type: ACTION_TYPE.ON_GET_OVERVIEW_DETAILS,
        payload: { value: parentBookingRes.data },
      });
      const query = `?voucherCode=${parentBookingRes?.data?.voucherData?.voucherDetails?.code}&parentBookingId=${bookingId}`;
      const listingVoucherDetailsRes = await getParentVoucherDetails(vehicleId, query);
      if (!listingVoucherDetailsRes?.status) {
        dispatch(hideLoading());
        toastError({ message: listingVoucherDetailsRes?.message });
        return;
      }

      if (
        listingVoucherDetailsRes?.status &&
        listingVoucherDetailsRes?.data &&
        listingVoucherDetailsRes?.data?.voucher?.validVoucher
      ) {
        dispatch({
          type: ACTION_TYPE.APPLY_PROMOTION,
          payload: listingVoucherDetailsRes?.data?.voucher?.voucherDetails,
        });
        dispatch(hideLoading());
        return;
      }

      dispatch(hideLoading());
    }
  };

export const getContractOptions = async listingId => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.GET_CONTRACT_LENGTH_OPTIONS}?listingId=${listingId}`,
  });
  return res?.data;
};

export const getVehiclePricing = async query => {
  const res = await REQUEST({
    method: 'GET',
    url: `${API_ENDPOINTS.GET_VEHICLE_PRICING}${query}`,
  });
  return res?.data;
};
